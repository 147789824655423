import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"

const Page = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicPage || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      <Section data={pageData.data} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query pagePageTemplateQuery($uid: String, $lang: String) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          text
          raw
        }
        description {
          text
        }
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        content {
          raw
        }
      }
    }
  }
`
